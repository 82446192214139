/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { HiMenuAlt4, HiX } from 'react-icons/hi';
import { motion } from 'framer-motion';
import { FaExternalLinkAlt } from 'react-icons/fa';
import './Navbar.scss';
import toast, { Toaster } from 'react-hot-toast';

const Navbar = () => {
  const [toggle, setToggle] = useState(false);
  const [showInfoCard, setShowInfoCard] = useState(true);

  const closeInfoCard = () => {
    setShowInfoCard(false);
  };

  useEffect(() => {
    // Hide the info card after 2 minutes
    const timeoutId = setTimeout(() => {
      setShowInfoCard(false);
    }, 3600000); // 2 minutes in milliseconds

    // Cleanup function to clear the timeout if component unmounts
    return () => clearTimeout(timeoutId);
  }, []);


  const openAppUrl = () => {
    const appUrl = 'https://gpt.varanbhalla.com';
    window.open(appUrl, '_blank');
  };

  return (
    <nav className="app__navbar">
      {/* <div className="app__navbar-logo">
        <img src={images.logo} alt="logo" />
      </div> */}
      <ul className="app__navbar-links">
        {['home', 'about', 'work', 'experience', 'contact'].map((item) => (
          <li className="app__flex p-text" key={`link-${item}`}>
            <div />
            <a href={`#${item}`}>{item}</a>
          </li>
        ))}
      </ul>

      {showInfoCard && (
        <div className="info-card">
          <br />
          <span>Ask your questions about Varan to VaranGPT.</span>
          <br />
          <br />
          <span>Example: 
            <br /> Varan's most used tech stacks ?</span>
          <br />
          <span>Varan's typing speed ?</span>
          <br />
          <span>His email id ?</span>
          <button className="close-button" onClick={closeInfoCard}>❌</button>
        </div>
      )}
        
        <button className="gpt-button" onClick={openAppUrl}>
        <span>Try VaranGPT</span>
        <FaExternalLinkAlt className="link-icon" />
      </button>

       



      <div className="app__navbar-menu">
        <HiMenuAlt4 onClick={() => setToggle(true)} />

        {toggle && (
          <motion.div
            transition={{ duration: 0.85, ease: 'easeOut' }}
          >
            <HiX onClick={() => setToggle(false)} />
            <ul>
              {['home', 'about', 'work', 'experience', 'contact'].map((item) => (
                <li key={item}>
                  <a href={`#${item}`} onClick={() => setToggle(false)}>
                    {item}
                  </a>
                </li>
              ))}
            </ul>
          </motion.div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
