import sanityClient from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';

export const client = sanityClient({
  projectId: '89glztef',
  dataset: 'production',
  apiVersion: '2022-11-15',
  useCdn: true,
  token: 'skVeyzr6oUSNh2MRLqgH6Apdj4YnSRR5t2PZbUolNrDlCspwG9K9Vw6Zq1OTNtXidQBfPvtMieMgEEEUNuRNoTYuBXK7edDUttqk8olkn8n0fUnxAVlbDCKCmyLADEoy0viVsFgXOrhOBbGwj1eFztXGpgvelpZ8tRbQcoLz3oijQEDTxZR9',
});

const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);
