import email from '../assets/email.png';
import mobile from '../assets/mobile.png';
import circle from '../assets/circle.svg';
import logo from '../assets/logo.png';
import contactGif from '../assets/contact.gif';

export default {
  email,
  mobile,
  circle,
  logo,
  contactGif,
};
