import React from 'react';
import { NavigationDots, SocialMedia } from '../components';

const AppWrap = (Component, idName, classNames) => function HOC() {
  return (
    <div id={idName} className={`app__container ${classNames}`}>
      {
        idName === 'home' || idName === 'contact' ? <SocialMedia /> : null
      }
      <div className="app__wrapper app__flex">
        <Component />

        {
          idName === 'home' || idName === 'contact'
            ? (
              <div className="copyright">
                <p className="p-text">Crafted by: VARAN</p>
              </div>
            )
            : null
        }
      </div>
      <NavigationDots active={idName} />
    </div>
  );
};

export default AppWrap;
