/* eslint-disable */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { BiLinkExternal } from 'react-icons/bi';
import ReactTooltip from 'react-tooltip';
// import { usePopperTooltip } from 'react-popper-tooltip';
import { AppWrap, MotionWrap } from '../../wrapper';
import { urlFor, client } from '../../client';
import './Experience.scss';
import 'react-popper-tooltip/dist/styles.css';

const Experience = () => {
  const [experiences, setExperiences] = useState([]);
  const [skills, setSkills] = useState([]);

  // const {
  //   getArrowProps,
  //   getTooltipProps,
  //   setTooltipRef,
  //   setTriggerRef,
  //   visible,
  // } = usePopperTooltip();
  // const [tooltipVisible, setTooltipVisible] = useState(visible);

  useEffect(() => {
    const query = '*[_type == "experiences"]';
    const skillsQuery = '*[_type == "skills"]';

    client.fetch(query).then((data) => {
      setExperiences(data);
    });

    client.fetch(skillsQuery).then((data) => {
      setSkills(data);
    });
  }, []);

  return (
    <>
      <h2 className="head-text">Skills & Experiences</h2>
      <div className="app__skills-container">
        <motion.div className="app__skills-list">
          {skills.map((skill) => (
            <motion.div
              whileInView={{ opacity: [0, 1] }}
              transition={{ duration: 0.5 }}
              className="app__skills-item app__flex"
              key={skill.name}
            >
              <div
                className="app__flex"
                style={{ backgroundColor: skill.bgColor }}
              >
                <img src={urlFor(skill.icon)} alt={skill.name} />
              </div>
              {skill.bgColor && <BiLinkExternal color="grey" style={{ position: 'absolute', marginTop: '40px', marginLeft: '30px' }} onClick={() => skill.bgColor && window.open(skill.bgColor, '_blank')} />}
              <p className="p-text">{skill.name}</p>
            </motion.div>
          ))}
        </motion.div>
        <div className="app__skills-exp">
          {experiences.map((experience) => (
            <motion.div
              className="app__skills-exp-item"
              key={experience.year}
            >
              <div className="app__skills-exp-year">
                <p className="bold-text">{experience.year}</p>
              </div>
              <motion.div className="app__skills-exp-works">
                {experience.works.map((work) => (
                  <>
                    <motion.div
                      whileInView={{ opacity: [0, 1] }}
                      transition={{ duration: 0.5 }}
                      className="app__skills-exp-work"
                      data-tip
                      data-for={work.name}
                      key={work.name}
                    >
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <h4 className="bold-text">{work.name}</h4>
                        {/*<BiLinkExternal color="grey" style={{ marginLeft: 5, marginTop: 3, fontSize: 18 }} />*/}
                      </div>
                      <p className="p-text">{work.company}</p>
                    </motion.div>

                    {/* 
                    <ReactTooltip
                      id={work.name}
                      effect="solid"
                      arrowColor="#fff"
                      className="skills-tooltip"
                    >
                      <div>
                        <h3>Tech</h3>
                        {work.desc}
                        <div>
                          <br />
                          <h3>Key Deliverables</h3>
                          <ul>
                            {work.deliverables.map((deliverable) => (
                              <li key={deliverable}>{deliverable}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </ReactTooltip>
                          */}

                    {/* <div className="App">
                      <button onClick={() => setTooltipVisible(!tooltipVisible)}>Close</button>
                      {tooltipVisible && (
                        <div>
                          <div
                            ref={setTooltipRef}
                            {...getTooltipProps({ className: 'tooltip-container' })}
                          >
                            <h3>Tech</h3>
                            {work.desc}
                            <div>
                              <br />
                              <h3>Key Deliverables</h3>
                              <ul>
                                {work.deliverables.map((deliverable) => (
                                  <li>{deliverable}</li>
                                ))}
                              </ul>
                            </div>
                          </div>
                          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
                        </div>
                      )}
                    </div> */}
                  </>
                ))}
              </motion.div>
            </motion.div>
          ))}
        </div>
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Experience, 'app__skills'),
  'experience',
  'app__whitebg',
);
