import React from 'react';
import { BsLinkedin, BsGithub } from 'react-icons/bs';
import { SiLeetcode } from 'react-icons/si';

const SocialMediaMobile = () => (
  <div className="app__social__mobile">
    <div onClick={() => window.open('https://www.linkedin.com/in/varanbhalla', '_blank')}>
      <BsLinkedin />
    </div>
    <div onClick={() => window.open('https://github.com/varanbhalla', '_blank')}>
      <BsGithub />
    </div>
    <div onClick={() => window.open('https://leetcode.com/varanbhalla', '_blank')}>
      <SiLeetcode />
    </div>
  </div>
);

export default SocialMediaMobile;
