import React from 'react';
// import { motion } from 'framer-motion';
import Vimeo from '@u-wave/react-vimeo';
import { AppWrap } from '../../wrapper';
// import { images } from '../../constants';
import './Header.scss';

// const scaleVariants = {
//   whileInView: {
//     scale: [0, 1],
//     opacity: [0, 1],
//     transition: {
//       duration: 1,
//       ease: 'easeInOut',
//     },
//   },
// };

const Header = () => (
  <div className="app__header app__flex">
    {/* <motion.div
      whileInView={{ x: [-100, 0], opacity: [0, 1] }}
      transition={{ duration: 0.5 }}
      className="app__header-info"
    >
      <div className="app__header-badge">
        <div className="badge-cmp app__flex">
          <span>👋</span>
          <div style={{ marginLeft: 20 }}>
            <p className="p-text">Hello, I am</p>
            <h1 className="head-text">Varan</h1>
          </div>
        </div>

        <div className="tag-cmp app__flex">
          <p className="p-text">Software Developer</p>
        </div>
      </div>
    </motion.div> */}

    <div>
      {/* <img src={images.profile} alt="profile_bg" /> */}
      <div className="intro__video">
        <Vimeo
          video={785545141}
          autoplay
          controls
          responsive
          loop={false}
          showTitle={false}
          color="1F2937"
        />
      </div>
      {/* <motion.img
        whileInView={{ scale: [0, 1] }}
        transition={{ duration: 1, ease: 'easeInOut' }}
        src={images.circle}
        alt="profile_circle"
        className="overlay_circle"
      /> */}
      <div className="intro__video-margin" />
    </div>

    {/* <motion.div
      variants={scaleVariants}
      whileInView={scaleVariants.whileInView}
      className="app__header-circles"
    >
      {[images.react, images.javascript, images.node].map((circle, index) => (
        <div className="circle-cmp app__flex" key={`circle-${index}`}>
          <img src={circle} alt="profile_bg" />
        </div>
      ))}
    </motion.div> */}
  </div>
);

export default AppWrap(Header, 'home');
